$grid-breakpoints: (
    xss:0,
    xs: 480px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1600px,
);
// $primary: #072753;
$primary: #153874;
$info: #4ecdc4;
$success: #22c96d;
$danger: #fa0510;
$warning: #e8c547;
$secondary: #ff9c00;
@import 'styles/functions.scss';
@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';
@import 'styles/utilities.scss';
@import 'styles/buttons.scss';
$extra-font-sizes: (
    "page-icon": 84px
);
$original-font-sizes: (
    "small": 12px,
    "smaller": 14px,
    "normal": 16px,
    "big": 18px,
    "bigger": 20px,
    "large": 24px,
    "larger": 32px,
    "xl": 40px,
    "xxl": 48px,
    "iconic": 60px
);

@function getFontSize($key) {
    @return map-get($original-font-sizes, $key);
}

@function add-font-sizes($custom-font-sizes) {
    @return map-merge($original-font-sizes, $custom-font-sizes);
}

.color-warning {
    color: $warning;
}

.color-error {
    color: $danger;
}

.font-weight-thin {
    font-weight: 100;
}

.font-weight-book {
    font-weight: 300;
}

.font-weight-regular {
    font-weight: 400;
}

.font-weight-medium {
    font-weight: 500;
}

.font-weight-semibold {
    font-weight: 600;
}

.font-family-cabin {
    font-family: Cabin;
}

@each $breakpoint in map-keys($grid-breakpoints) {
    $font-sizes: add-font-sizes($extra-font-sizes);

    @each $font-size-prefix,
    $font-size-value in $font-sizes {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            .font#{$infix}-#{$font-size-prefix} {
                font-size: $font-size-value;
            }
        }
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {

    // Generate media query if needed
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        // Loop over each utility property
        @each $key,
        $utility in $utilities {

            // The utility can be disabled with `false`, thus check if the utility is a map first
            // Only proceed if responsive media queries are enabled or if it's the base media query
            @if type-of($utility)=="map" and (map-get($utility, responsive) or $infix=="") {
                @include generate-utility($utility, $infix);
            }
        }
    }
}

//
// Alternate buttons
//
@each $color,
$value in $theme-colors {
    .btn-#{$color} {
        @include button-variant($value, $value);
    }
}

@each $color,
$value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant($value);
    }
}

@include media-breakpoint-down(sm) {
    nz-layout {
        max-width: 100% !important;
        display: block !important;

        nz-content {
            height: 100%;
            margin-top: 0px !important;
        }
    }

    .ant-table-wrapper {
        overflow: auto;
    }

    .ant-drawer-content-wrapper {
        width: 100% !important;
    }
}

.ant-drawer-header {
    background: $primary;

    .ant-drawer-title {
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 2px;
    }
}

.ant-drawer-close i {
    color: $secondary;
}

.clickable {
    cursor: pointer;
}

.btn-secondary {
    color: white;
}

body {
    overflow-x: hidden;
}

@include media-breakpoint-down(md) {
    #article-title {
        h2 {
            padding-left: 0px !important;
        }

        .mobile-icon{
            top: -40px !important;
        }
    }
}

.safe-bottom {
    padding-bottom: env(safe-area-inset-bottom);
}

.medium-zoom-image {
    z-index: 1;
}

@media (max-width: 551.98px) {
    .ant-btn>.anticon+span,
    .ant-btn>span+.anticon {
        margin-left: 0px !important;
    }
}

.ck-content .image img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
}