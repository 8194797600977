@mixin gradient-bg($color, $foreground: null) {
    @if $enable-gradients {
        @if $foreground {
            background-image: $foreground, linear-gradient(180deg, mix($body-bg, $color, 15%), $color);
        }
        @else {
            background-image: linear-gradient(180deg, mix($body-bg, $color, 15%), $color);
        }
    }
    @else {
        background-color: $color;
    }
}

// Horizontal gradient, from left to right
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
@mixin gradient-x($start-color: $gray-700, $end-color: $gray-800, $start-percent: 0%, $end-percent: 100%) {
    background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent);
}

// Vertical gradient, from top to bottom
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
@mixin gradient-y($start-color: $gray-700, $end-color: $gray-800, $start-percent: null, $end-percent: null) {
    background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent);
}

@mixin gradient-directional($start-color: $gray-700, $end-color: $gray-800, $deg: 45deg) {
    background-image: linear-gradient($deg, $start-color, $end-color);
}

@mixin gradient-x-three-colors($start-color: $blue, $mid-color: $purple, $color-stop: 50%, $end-color: $red) {
    background-image: linear-gradient(to right, $start-color, $mid-color $color-stop, $end-color);
}

@mixin gradient-y-three-colors($start-color: $blue, $mid-color: $purple, $color-stop: 50%, $end-color: $red) {
    background-image: linear-gradient($start-color, $mid-color $color-stop, $end-color);
}

@mixin gradient-radial($inner-color: $gray-700, $outer-color: $gray-800) {
    background-image: radial-gradient(circle, $inner-color, $outer-color);
}

@mixin gradient-striped($color: rgba($white, .15), $angle: 45deg) {
    background-image: linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
}

@mixin box-shadow($shadow...) {
    @if $enable-shadows {
        $result: ();
        @if (length($shadow)==1) {
            // We can pass `@include box-shadow(none);`
            $result: $shadow;
        }
        @else {
            // Filter to avoid invalid properties for example `box-shadow: none, 1px 1px black;`
            @for $i from 1 through length($shadow) {
                @if nth($shadow, $i) !="none" {
                    $result: append($result, nth($shadow, $i), "comma");
                }
            }
        }
        @if (length($result) > 0) {
            box-shadow: $result;
        }
    }
}

@mixin button-variant( $background, $border, $color: color-yiq($background), $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $hover-color: color-yiq($hover-background), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%), $active-color: color-yiq($active-background)) {
    color: $color;
    @include gradient-bg($background);
    border-color: $border;
    @include box-shadow($btn-box-shadow);
    &:hover {
        color: $hover-color;
        @include gradient-bg($hover-background);
        border-color: $hover-border;
    }
    &:focus,
    &.focus {
        color: $hover-color;
        @include gradient-bg($hover-background);
        border-color: $hover-border;
        // Avoid using mixin so we can pass custom focus shadow properly
        @if $enable-shadows {
            box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), .5);
        }
        @else {
            box-shadow: 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), .5);
        }
    }
    &:active,
    &.active,
    .show>&.dropdown-toggle {
        color: $active-color;
        background-color: $active-background;
        // Remove CSS gradients if they're enabled
        background-image: if($enable-gradients, none, null);
        border-color: $active-border;
        &:focus {
            // Avoid using mixin so we can pass custom focus shadow properly
            @if $enable-shadows and $btn-active-box-shadow !=none {
                box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), .5);
            }
            @else {
                box-shadow: 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), .5);
            }
        }
    }
}

@mixin button-outline-variant( $color, $color-hover: color-yiq($color), $active-background: $color, $active-border: $color, $active-color: color-yiq($active-background)) {
    color: $color;
    border-color: $color;
    &:hover {
        color: $color-hover;
        background-color: $active-background;
        border-color: $active-border;
    }
    &:focus,
    &.focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
    }
    &:active,
    &.active,
    .show>&.dropdown-toggle {
        color: $active-color;
        background-color: $active-background;
        border-color: $active-border;
        &:focus {
            // Avoid using mixin so we can pass custom focus shadow properly
            @if $enable-shadows and $btn-active-box-shadow !=none {
                box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, .5);
            }
            @else {
                box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
            }
        }
    }
    &:disabled,
    &.disabled {
        color: $color;
        background-color: transparent;
    }
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $border-radius) {
    padding: $padding-y $padding-x;
    @include font-size($font-size);
    // Manually declare to provide an override to the browser default
    @include border-radius($border-radius, 0);
}